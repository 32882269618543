import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

function onloadCallback() {
  if ($("#google-captcha").length) {
    grecaptcha.render("google-captcha", {
      sitekey: "6LeSL1kaAAAAAIahcDk8mGPsqe9Kwb1UgDf24oRF",
    });
  }
}

window.onloadCallback = onloadCallback;

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  



  require("./age-checker");
  require("./page-load");
  require("./cookie-bar");
  require("./animations");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./counter-animation");
  require("./gsap-animation");
  require("./images-slider");
  require("./user/user-ajax-forms");
  require("./user/user-wish-list");
  require("./scroll-to-id");
  require("./get-localization");
  require("./proposal");
  require("./vote");
  require("./set-voting-project-data");

  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox({
      showCounter: false,
    });
  }
});

// $(window).on("ajaxInvalidField", function(
//   event,
//   fieldElement,
//   fieldName,
//   errorMsg,
//   isFirst
// ) {
//   $(fieldElement)
//     .closest(".form-group")
//     .addClass("has-error");
// });

// $(document).on("ajaxPromise", "[data-request]", function() {
//   $(this)
//     .closest("form")
//     .find(".form-group.has-error")
//     .removeClass("has-error");
// });


const mediaBaner = document.getElementById("media-baner");

if(mediaBaner){
  mediaBaner.addEventListener("click", function() {
    window.open(
      'https://www.facebook.com/BosmanSzczecinski/','name','width=1920,height=1080'
    );
  })
}






























