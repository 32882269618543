import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


const elements = [...document.querySelectorAll(".translate")];
gsap.registerPlugin(ScrollTrigger);

if(elements.length){
    window.addEventListener('load', function () {
        
    
      elements.forEach((elem) => {

        const item = $(elem).find("img").get();
        // const xPos = screen.width / 15;
        const yPos = screen.width / screen.height * 50;
        // const endPos = elem.offsetHeight - item.offsetHeight / 2;
        let tl = gsap.timeline();

        // console.log(elem.offsetHeight);

        tl.from( item, { y: 0, duration: 4 });
        tl.to(item, {y: yPos + 'px', duration: 4});

        ScrollTrigger.create({
          trigger: elem,
          animation: tl,
          markers: false,
          start: "top center",
          scrub: true,
          end: "10% top"
        });

      });


    });

    elements.forEach((item) => {

      window.addEventListener("mousemove", function(e) {
        const xPos = e.clientX / screen.width - 0.5,
              yPos = e.clientY / screen.height - 0.5;

  
              gsap.to(item, 0.6, {
                x: 10 * yPos,
                y: 10 * xPos,
                ease: "easeInOut",
                transformPerspective: 900,
                transformOrigin: "center",
              });
      });

    })


    
}

