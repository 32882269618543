const video = document.getElementById("topVideo");
if (video) {
  video.addEventListener("canplay", (event) => {
    const heading = document.getElementById("top-heading");
    if (heading) {
      heading.classList.add("animated");
    }
  });
}


window.addEventListener('load', function () {
  const title = document.getElementById("top-title");
  if(title){
     title.classList.add("animate");
  }
 });