$(function() {
  $("#other-photos-slider").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: $(".news__arrow--prev"),
    nextArrow: $(".news__arrow--next"),
    infinite: true,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });
});
