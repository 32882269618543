$(window).on("load", function() {
  const inputs = [...document.getElementsByClassName("form__input")];
  if (inputs.length) {
    function onFocus(input) {
      if (input.value) {
        input.classList.add("form__input--has-content");
      } else {
        input.classList.remove("form__input--has-content");
      }
    }
    inputs.forEach(function(input) {
      input.addEventListener("focus", function() {
        onFocus(input);
      });

      input.addEventListener("blur", function() {
        onFocus(input);
      });

      onFocus(input);
    });
  }
});
