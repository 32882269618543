const ageConfirmModal = document.getElementById("age-confirm-modal");

function OpenAdModal() {
  $("#myModal").modal("show");
}

function OpenModal() {
  document.body.classList.add("modal-open");
  ageConfirmModal.classList.add("active");
}

function CloseModal() {
  document.body.classList.remove("modal-open");
  ageConfirmModal.classList.remove("active");
  if ($("#myModal").length > 0) {
    OpenAdModal();
  }
}

function ConfirmAge() {
  localStorage.setItem("isAdult", "yes");
  CloseModal();
}

function DiscardAge() {
  localStorage.setItem("isAdult", "no");
}

if (localStorage.getItem("isAdult")) {
  const isAdult = localStorage.getItem("isAdult");

  if (isAdult === "no") {
    OpenModal();
  } else {
    if ($("#myModal").length > 0) {
      OpenAdModal();
    }
  }
} else {
  DiscardAge();
  OpenModal();
}

if (ageConfirmModal) {
  const accept = document.getElementById("accept-age");
  const discard = document.getElementById("discard-age");

  accept.addEventListener("click", function() {
    ConfirmAge();
  });

  discard.addEventListener("click", function() {
    const info = document.getElementById("age-confirm-info");
    info.innerHTML = "<p>Nie możesz wejść na stronę. Przepraszamy.</p>";
    DiscardAge();
  });
}
// //modal
// const modal = document.getElementById("widget-modal");
// const modalClose = document.getElementById("modal-close");
// siteWrapper.appendChild(modal);

// //modal close handle
// modalClose.addEventListener("click", function(e) {
//   e.preventDefault();
//   modal.classList.remove("is-open");
//   document.body.classList.remove("modal-open");
//   modalFloors.forEach((floor) => {
//     floor.classList.remove("active");
//   });
// });
