export const ERROR_MESSAGE =
  "Wystąpił nieoczekiwany błąd, odśwież stronę i spróbuj ponownie";

export function showMessageAboveForm(form, message, error = false) {
  let messageBox = $(form).find(".message-ajax");
  //   $(".message-ajax");
  if (form.length > 0 && messageBox.length === 0) {
    const messageHTML =
      '<div class="alert message-ajax" role="alert" style="display: none;"><p></p></div>';
    // $().insertBefore(form);
    $(form).prepend(messageHTML);
  }
  if (form.length > 0) {
    // if (messageBox.hasClass("alert-danger")) {
    //   messageBox.removeClass("alert-danger");
    // }

    if (error) {
      if (!messageBox.hasClass("alert-danger")) {
        $(form)
          .find(".message-ajax")
          .addClass("alert-danger");
      }

      if (
        $(form)
          .find(".message-ajax")
          .hasClass("alert-success")
      ) {
        $(form)
          .find(".message-ajax")
          .removeClass("alert-success");
      }
    } else {
      if (
        !$(form)
          .find(".message-ajax")
          .hasClass("alert-success")
      ) {
        $(form)
          .find(".message-ajax")
          .addClass("alert-success");
      }

      if (
        $(form)
          .find(".message-ajax")
          .hasClass("alert-danger")
      ) {
        $(form)
          .find(".message-ajax")
          .removeClass("alert-danger");
      }
    }

    messageBox = $(form).find(".message-ajax");
    messageBox.find("p").text(message);

    messageBox.fadeIn("fast");
    scrollToTarget(".message-ajax");
  } else {
    return false;
  }
}

export function scrollToTarget(target) {
  const $target = $(target);
  if ($target.length > 0 && $target.offset().top < window.pageYOffset) {
    $(window).scrollTop($target.offset().top - 100);
  }
}

export function loader(form, hideLoader = false) {
  let loader = $(form).find(".ajaxLoader");
  let messageBox = $(".message-ajax");

  if (messageBox.length > 0 && !hideLoader) {
    messageBox.hide();
  }
  if (hideLoader) {
    loader.hide();
  } else {
    loader.show();
  }
}
