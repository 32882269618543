import {
    showMessageAboveForm,
    loader,
    ERROR_MESSAGE,
    scrollToTarget,
  } from "./show-message-above-form"









$("#vote").click(function(e) {
    e.preventDefault();
    const form = $("#voting-form");
    const formData = {
        email: document.getElementsByName("email")[0].value,
        project: document.getElementsByName("project")[0].value,
        company: document.getElementsByName("company")[0].value,
        proposal_id: document.getElementsByName("proposal_id")[0].value
    };

    if (grecaptcha.getResponse() !== "") {

      $.request("onVotingSSI", {
        data: formData,
        success: function() {
          const msg = 'Dziękujemy za oddanie głosu! Prosimy potwierdzić swój wybór klikając w link aktywacyjny wysłany na podany adres email.';
          form.find('input[name="email"]').val("");
          showMessageAboveForm(form, msg);
        },
        beforeSend: function() {
          loader(form, false);
        },
        complete: function() {
          loader(form, true);
        },
        error: function(data) {
          
          const msg = data.responseJSON.X_OCTOBER_ERROR_MESSAGE;
          
          showMessageAboveForm(form, msg, true);
        },
      });

    } else {

      const msg = "Potwierdź, że nie jesteś robotem";
      showMessageAboveForm(form, msg, true);

    }


  });

