import {
    showMessageAboveForm,
    loader,
    ERROR_MESSAGE,
    scrollToTarget,
  } from "./show-message-above-form"

$("#addProposal").click(function(e) {
    e.preventDefault();
    const form = $("#proposal-form");
    const formData = {
        company_name: document.getElementsByName("company_name")[0].value,
        address: document.getElementsByName("address")[0].value,
        zip_code: document.getElementsByName("zip_code")[0].value,
        city: document.getElementsByName("city")[0].value,
        country: document.getElementsByName("country")[0].value,
        nip: document.getElementsByName("nip")[0].value,
        krs: document.getElementsByName("krs")[0].value,
        account: document.getElementsByName("account")[0].value,
        company_description: document.getElementsByName("company_description")[0].value,
        ngo_name: document.getElementsByName("ngo_name")[0].value,
        ngo_lastname: document.getElementsByName("ngo_lastname")[0].value,
        ngo_email: document.getElementsByName("ngo_email")[0].value,
        ngo_tel: document.getElementsByName("ngo_tel")[0].value,
        project_title: document.getElementsByName("project_title")[0].value,
        project_type: document.getElementsByName("project_type")[0].value,
        purpose: document.getElementsByName("purpose")[0].value,
        project_desctiption: document.getElementsByName("project_desctiption")[0].value,
        benefits: document.getElementsByName("benefits")[0].value,
        team: document.getElementsByName("team")[0].value,
        amount: document.getElementsByName("amount")[0].value,
        amount_full: document.getElementsByName("amount_full")[0].value,
        range: document.getElementsByName("range")[0].value,
        ad: document.getElementsByName("ad")[0].value,
        start_day: document.getElementsByName("start_day")[0].value,
        start_month: document.getElementsByName("start_month")[0].value,
        start_year: document.getElementsByName("start_year")[0].value,
        end_day: document.getElementsByName("end_day")[0].value,
        end_month: document.getElementsByName("end_month")[0].value,
        end_year: document.getElementsByName("end_year")[0].value,
        schedule: document.getElementsByName("schedule")[0].value,
        accept: form.find('input[name="accept"]:checked').val(),
        regulations: form.find('input[name="regulations"]:checked').val(),
        email: document.getElementsByName("email")[0].value,
        user_id: document.getElementsByName("user_id")[0].value,


    };

      $.request("onSend", {
        data: formData,
        success: function(data) {
          const msg = 'Wniosek został złożony, w razie potrzeby wciąż możesz go edytować w panelu klienta.';
          handleAjaxForm(form, "onSave", msg, true);
        },
        beforeSend: function() {
          loader(form, false);
        },
        complete: function() {
          loader(form, true);
        },
        error: function(data) {
          const msg = data.responseJSON.X_OCTOBER_ERROR_MESSAGE;
          
          showMessageAboveForm(form, msg, true);
        },
      });

  });




$("#editProposal").click(function(e) {
  e.preventDefault();
  const form = $("#proposal-form");
  const formData = {
      company_name: document.getElementsByName("company_name")[0].value,
      address: document.getElementsByName("address")[0].value,
      zip_code: document.getElementsByName("zip_code")[0].value,
      city: document.getElementsByName("city")[0].value,
      country: document.getElementsByName("country")[0].value,
      nip: document.getElementsByName("nip")[0].value,
      krs: document.getElementsByName("krs")[0].value,
      account: document.getElementsByName("account")[0].value,
      company_description: document.getElementsByName("company_description")[0].value,
      ngo_name: document.getElementsByName("ngo_name")[0].value,
      ngo_lastname: document.getElementsByName("ngo_lastname")[0].value,
      ngo_email: document.getElementsByName("ngo_email")[0].value,
      ngo_tel: document.getElementsByName("ngo_tel")[0].value,
      project_title: document.getElementsByName("project_title")[0].value,
      project_type: document.getElementsByName("project_type")[0].value,
      purpose: document.getElementsByName("purpose")[0].value,
      project_desctiption: document.getElementsByName("project_desctiption")[0].value,
      benefits: document.getElementsByName("benefits")[0].value,
      team: document.getElementsByName("team")[0].value,
      amount: document.getElementsByName("amount")[0].value,
      amount_full: document.getElementsByName("amount_full")[0].value,
      range: document.getElementsByName("range")[0].value,
      ad: document.getElementsByName("ad")[0].value,
      start_day: document.getElementsByName("start_day")[0].value,
      start_month: document.getElementsByName("start_month")[0].value,
      start_year: document.getElementsByName("start_year")[0].value,
      end_day: document.getElementsByName("end_day")[0].value,
      end_month: document.getElementsByName("end_month")[0].value,
      end_year: document.getElementsByName("end_year")[0].value,
      schedule: document.getElementsByName("schedule")[0].value,
      email: document.getElementsByName("email")[0].value,
      user_id: document.getElementsByName("user_id")[0].value,


  };

    $.request("onSend", {
      data: formData,
      success: function(data) {
        const msg = 'Zapisano!';
        handleAjaxForm(form, "onSave", msg, false);
      },
      beforeSend: function() {
        loader(form, false);
      },
      complete: function() {
        loader(form, true);
      },
      error: function(data) {
        console.log(data.responseJSON)
        const msg = data.responseJSON.X_OCTOBER_ERROR_MESSAGE;
        
        showMessageAboveForm(form, msg, true);
      },
    });

});
















function handleAjaxForm(form, ajaxRequest, successMsg = false, clearFields) {
  form.request(ajaxRequest, {
    success: function(data) {
      const message = {
        content: data.message || successMsg,
        status: data.hasOwnProperty("status") ? !data.status : false,
      };

      if(clearFields){
          form.find('input, textarea').val("");
      }

      if (message.content) {
        showMessageAboveForm(form, message.content, message.status);
      }

      if (data.X_OCTOBER_REDIRECT) {
        window.location = data.X_OCTOBER_REDIRECT;
      }
    },
    beforeSend: function() {
      loader(form, false);
    },
    complete: function() {
      loader(form, true);
    },
    error: function(data) {
      showMessageAboveForm(form, ERROR_MESSAGE, true);
    },
  });
}