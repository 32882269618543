// if(document.getElementById('proposal-form')){
//     const addres = document.getElementById('implementation_address');
//     const zip = document.getElementById('implementation_zip');
//     const city = document.getElementById('implementation_city');
//     const lat = document.getElementById('lat');
//     const lon = document.getElementById('lon');
//     const searchLocalization = document.getElementById('find_localization');


//     function getLocalization(addres, zip, city) {

//         fetch(`https://nominatim.openstreetmap.org/search/${addres} ${zip} ${city}?format=json&addressdetails=1&limit=1`)
//         .then((response) => {
//             return response.json();
//         })
//         .then((data) => {
            
//             if(data.length){
//                 const localization = data[0];
//                 lat.value = localization.lat;
//                 lon.value = localization.lon;

//             }else{
//                 throw 'Nie znaleziono lokazizacji o podanym adresie';
//             }
//         })
//         .catch((err) => {
//                 console.error(err);
//             }
//         );
//     }


//     searchLocalization.addEventListener("click", function() {

//         getLocalization(addres.value, zip.value, city.value);
//     });


// }