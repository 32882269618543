const buttons = [...document.getElementsByClassName("vote-list-btn")];


if(buttons.length){

    buttons.forEach(function(item) {
        item.addEventListener("click", function(e) {
            const proposalId = e.target.dataset.id;
            const porojectName = e.target.dataset.title;
            const porojectCompany = e.target.dataset.company;
            
            document.getElementById("votingFormId").value = proposalId;
            document.getElementById("votingFormProject").value = porojectName;
            document.getElementById("votingFormCompany").value = porojectCompany;
        })
    })

}